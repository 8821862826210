import React from "react"
import Helmet from "react-helmet";
import Navbar from './Navbar/header'; 
import Footer from './Footer'; 

import './layout.css'

import AOS from 'aos';
import 'aos/dist/aos.css';

const Layout = (props) => {
  const { children, location, meta, scrollTop } = props;
  return (
    <div id="layout" className={ scrollTop > 50 ? "scrolled" : "" }>
      <Helmet
        defaultTitle={(meta && meta.title) || 'Toni\'s Gardiner'}
      >

        {meta && meta.description && <meta name="description" content={meta.description} />}

        {meta && meta.keywords && <meta name="keywords" content={meta.keywords} />}

        {/* <!-- Start of HubSpot Embed Code -->
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/5613249.js"></script>
        <!-- End of HubSpot Embed Code --> */}

        {/* Meta Data */}
        {
          meta && meta.robots && <meta property="robots" content={meta.robots} />
        }

        {/* Cookie bot scripts START */}
        <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="716bf630-e1f9-4973-8895-de043cfa56b5" type="text/javascript" async></script>
        {/* Cookie bot scripts END */}

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={(meta && meta.title) || 'tonis gardiner'} />

      </Helmet>
      <Navbar
        location={location}
      />
      <div id="main-body">
        {children}
        <Footer />
      </div>
    </div>
  )
}

class LayoutComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
      windowWidth: 0
    }
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    this.setState({
      scrollTop: document.scrollingElement && document.scrollingElement.scrollTop ? document.scrollingElement.scrollTop : document.documentElement.scrollTop
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.setState({ windowWidth: window.innerWidth })

    AOS.init(); 

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { children, location, meta } = this.props;
    const { scrollTop, windowWidth } = this.state;

    return (
      <Layout
        children={children}
        location={location}
        meta={meta}
        scrollTop={scrollTop}
        windowWidth={windowWidth}
      />
    )
  }
}

export default LayoutComponent;