import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import DangerousHTML from 'react-dangerous-html';

import './styles.css'; 

const Template = (props) => {
  const data = props.data.markdownRemark;
  const { meta, title } = data.frontmatter;
  const { location } = props;
  return (
    <Layout location={location} meta={meta}>
      <header data-aos='fade-in' className='page-title'>
        <h1>
          { title }
          <span style={{width: 100, height: 6, margin: '8px auto 0', display: 'block', backgroundColor: '#29699B'}}></span>
        </h1>
      </header>
      <div className='page-content'>
        {data.html && <DangerousHTML html={data.html} />}
        <div className='vertical-rule' />
        <img src={data.frontmatter.image} alt={data.frontmatter.title} className='product-image' />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: {path: {eq:$path}}) {
    	frontmatter{
        title
        path 
        image
        meta {
          title
          description
          keywords
          robots
        }
      }
      html
    }
  }
`

export default Template;