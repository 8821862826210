import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// icons
import MenuIcon from '@material-ui/icons/Menu';
import Home from '@material-ui/icons/Home';
import Info from '@material-ui/icons/Info';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
import Contact from '@material-ui/icons/PermContactCalendar';

import './navbar.css';

import { Link } from 'gatsby'

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
    fontSize: '1.5rem'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    [theme.breakpoints.up('sm')] : {
      display: 'none',
    }
  },
  appBar: {
    height: 65,
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
    color: "#929294"
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navigation: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    display: 'flex'
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#fafaff',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  navigationName: {
    fontSize: '1.35em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1em'
    }
  }
});

class Navbar extends React.Component {
  state = {
    mobileOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { classes, theme } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem button style={{padding: '0 0 0 25px', height: 50}}>
            <Link onClick={this.handleDrawerToggle} className='drawerLink' activeClassName="activeMobile" to="/">
              <ListItemIcon><Home /></ListItemIcon>
              <ListItemText primary={'Forside'} />
            </Link>
          </ListItem>
          <ListItem button style={{padding: '0 0 0 25px', height: 50}}>
            <Link onClick={this.handleDrawerToggle} className='drawerLink' activeClassName="activeMobile" to="/produkter">
              <ListItemIcon><ViewCarousel /></ListItemIcon>
              <ListItemText primary={'Produkter'} />
            </Link>
          </ListItem>
          <ListItem button style={{padding: '0 0 0 25px', height: 50}}>
            <Link onClick={this.handleDrawerToggle} className='drawerLink' activeClassName="activeMobile" to="/omos">
              <ListItemIcon><Info /></ListItemIcon>
              <ListItemText primary={'Om os'} />
            </Link>
          </ListItem>
          <ListItem button style={{padding: '0 0 0 25px', height: 50}}>
            <Link onClick={this.handleDrawerToggle} className='drawerLink' activeClassName="activeMobile" to="/kontakt">
              <ListItemIcon><Contact /></ListItemIcon>
              <ListItemText primary={'Kontakt'} />
            </Link>
          </ListItem>
        </List>
        <Divider />
      </div>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={`header ${classes.appBar}`}>
          <Toolbar style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" noWrap style={{fontFamily: 'Lobster, cursive'}} className={classes.navigationName}>
                  Toni's Gardiner
                </Typography>
            </div>
            <nav className={classes.navigation}>
              <Link className='navLink' activeClassName="active" to="/">
                Forside
                <span className='navUnderline'></span>
              </Link>
              <Link className='navLink' activeClassName="active" to="/produkter">
                Produkter
                <span className='navUnderline'></span>
              </Link>
              <Link className='navLink' activeClassName="active" to="/omos">
                Om os
                <span className='navUnderline'></span>
              </Link>
              <Link className='navLink' activeClassName="active" to="/kontakt">
                Kontakt
                <span className='navUnderline'></span>
              </Link>
            </nav>
            <div style={{width: 160}} />
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>
    );
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Navbar);
