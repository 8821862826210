import React from 'react';
import { graphql, StaticQuery } from 'gatsby'; 
import './styles.css';

const DataList = ({ arr }) => {
  return (
    <ul>
      {
        arr.map((item, i) => {
          if (item.link) {
            return (
              <li key={i}>
                <a href={ item.link }>{ item.text }</a>
              </li>
            )
          } else {
            return (
              <li key={i}>{ item.text }</li>
            )
          }
        })
      }
    </ul>
  ) 
}

const Footer = () => {
  return (
    <StaticQuery query={graphql`
      query {
        markdownRemark (frontmatter: {title: {eq: "footer"}}) {
          frontmatter {
            contact {
              text
              link
            }
            links {
              text
              link
            }
            privacy {
              text
              link
            }
          }
        }
      }
    `}
    render={data => {
      const { contact, links, privacy } = data.markdownRemark.frontmatter; 
      return (
        <footer>
          <div id='footer-content'>
            <div className='footer-item'>
              <p><strong>Kontakt:</strong></p>
              <DataList arr={ contact } />
            </div>
            <div className='footer-item'>
              <p><strong>Links:</strong></p>
              <DataList arr={links} />
            </div>
            <div className='footer-item'>
              <p><strong>Privacy:</strong></p>
              <DataList arr={privacy} />
            </div>
          </div>
          <div style={{width: '90vw', margin: '35px 0'}}>Copyright &copy; { new Date().getFullYear() } Toni's Gardiner: Salg og Montage</div>
          <p id='madeBy'>Lavet af: <a href='https://www.github.com/MikkelWestermann'>Mikkel Theiss Westermann</a></p>
        </footer> 
      )
    }}
    />
  )
}

export default Footer;
